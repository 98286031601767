.p-schedule {
  width: 100%;
  max-width: 750px;
  padding: 0 20px;
  margin: 0 auto;

  &-title {
    font-size: rem(28px);
    letter-spacing: 0.03em;
    margin-bottom: 30px;
    text-align: center;

    @include view-at(sp) {
      margin-bottom: 20px;
    }
  }

  &-table {
    width: 100%;

    tbody {
      tr {
        border-bottom: 1px solid $color-gray;
      }

      th {
        font-weight: normal;
      }

      th,
      td {
        padding: 20px;
        text-align: center;
        border-right: 1px solid $color-gray;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
