.p-accordion {
  &-list {
    &-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-header {
    display: block;
    font-size: rem(18px);
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $color-white;
    font-weight: $bold;
    padding: 16px 56px 16px 16px;
    background-color: #e37e5d;
    position: relative;
    margin-bottom: 4px;

    &::after {
      font-family: "Material Symbols Outlined";
      content: "\ead0";
      font-size: 1.5em;
      font-weight: $regular;
      position: absolute;
      top: calc(50% - 0.75em);
      right: 16px;
      transition: all 0.3s;
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &-contents {
    display: none;
    border: 1px solid #e37e5d;
    padding: 16px;

    &.visible {
      display: block;
    }
  }

  &-answer {
    line-height: 1.5;
    letter-spacing: 0.05em;
  }
}
