/* --------------------------
フォーム
-------------------------- */

.c-form {
  @for $i from 0 through 10 {
    $width: (10% * $i);
    &-w#{$i * 10} {
      width: $width;
    }
  }

  @include view-at(sp) {
    @for $i from 0 through 10 {
      $width: (10% * $i);
      &-w#{$i * 10}--sp {
        width: $width;
      }
    }
  }

  &-text {
    border: 1px solid $color-gray;
    padding: 6px 5px;

    &:invalid {
      border: 2px solid #b11010;
      background: rgba(177, 16, 16, 0.25);
      background-image: url(../images/common/icon-invalid.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }

  &-textarea {
    border: 1px solid $color-gray;
    width: 100%;
    padding: 6px 5px;
    height: 100px;
  }

  &-checkbox {
    &-input {
      display: none;

      &:checked + .c-form-checkbox-span::after {
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        left: 6px;
        width: 8px;
        height: 12px;
        transform: rotate(40deg);
        border-bottom: 3px solid $color-black;
        border-right: 3px solid $color-black;
      }
    }

    &-span {
      display: inline-block;

      padding-left: calc(20px + 0.5em);
      padding-right: 0.5em;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $color-gray;
        background-color: $color-white;
      }
    }
  }

  &-radio {
    &-input {
      display: none;

      &:checked + .c-form-radio-span::after {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-black;
      }
    }

    &-span,
    .mwform-radio-field-text {
      display: inline-block;

      padding-left: calc(20px + 0.5em);
      padding-right: 0.5em;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid $color-gray;
        background-color: $color-white;
      }
    }
  }

  &-select {
    display: block;
    padding: 6px 5px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    appearance: none;
    border: 0;
    border: 1px solid $color-gray;
    background-color: $color-white;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' class='svg-inline--fa fa-angle-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }

    &:hover {
      border-color: #888;
    }

    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }

    &--small {
      width: 50%;
      display: inline-block;
    }

    option {
      font-weight: normal;
    }
  }
}
