/* --------------------------
フッター固定ボタン
-------------------------- */

.p-fixedmenu {
  display: none;

  @include view-at(sp) {
    display: block;
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 8px 24px;
    background-color: rgba($color-brown, 0.75);
  }

  &-list {
    display: flex;
    margin: -0.5em;

    &-item {
      width: calc(100% / 3);
      padding: 0.5em;
      text-align: center;

      a {
        display: block;

        img {
          width: 72px;
        }
      }
    }
  }
}
