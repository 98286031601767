/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  display: flex;
  align-items: center;

  @include view-at(tab) {
    display: none;
    top: 50px;
    height: 100vh;
    background: rgba($color-brown, 0.9);
    padding: 0 32px;
  }

  &-list {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include view-at(tab) {
      height: auto;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-item {
      margin-right: 2em;

      @include view-at(tab) {
        margin-right: 0;
        width: 100%;
        padding: 8px 0;
      }

      &.current {
        a {
          color: $color-orange;
        }
      }

      a {
        display: inline-block;
        font-size: rem(12px);
        line-height: 1;
        font-weight: $bold;
        letter-spacing: 0.1em;
        color: $color-white;
        transition: all 0.3s;

        &:hover {
          color: #dc5f36;
        }

        @include view-at(tab) {
          font-size: rem(16px);
          line-height: 1.5;
        }
      }
    }
  }

  &-button {
    height: 100px;
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(14px);
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $color-white;
    background: $color-orange;
    text-align: center;

    @include view-at(tab) {
      display: none;
    }
  }

  &-sns {
    display: none;

    @include view-at(tab) {
      display: block;
      margin-top: 16px;

      &-list {
        display: flex;
        margin: -0.5em;

        &-item {
          padding: 0.5em;

          a {
            display: block;

            img {
              width: 40px;
            }
          }
        }
      }
    }
  }
}
