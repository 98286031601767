// Icomoonから設定　style.cssコピペ
// url()のパス注意
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?nxc2oh');
    src:  url('../fonts/icomoon.eot?nxc2oh#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?nxc2oh') format('truetype'),
      url('../fonts/icomoon.woff?nxc2oh') format('woff'),
      url('../fonts/icomoon.svg?nxc2oh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.icon-home:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e912";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
