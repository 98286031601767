html {
  font-size: 16px;
  visibility: hidden;

  &.wf-active,
  &.wf-inactive {
    visibility: visible;
  }
}

body {
  font-feature-settings: "palt";
  font-family: "Noto Sans JP", $sans-serif, sans-serif;
  font-weight: $regular;
  color: $color-black;
  font-size: rem(16px);
}

a {
  color: $color-black;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 0.6;

    img {
      opacity: 0.6;
    }
  }
}

p {
  margin-bottom: 1em;
  font-weight: $regular;
  line-height: 1.5;
  letter-spacing: 0.05em;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 100px;

  @include view-at(sp) {
    padding-top: 50px;
  }
}
