.p-sns {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 5;

  @include view-at(sp) {
    display: none;
  }

  &-wrapper {
    width: 110px;
    height: 110px;
    padding-right: 16px;
    background: $color-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
  }

  &-text {
    font-size: rem(12px);
    font-weight: $bold;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $color-white;
    margin-bottom: 0;
  }

  &-icon {
    padding: 0.5em;
    display: block;
    text-align: center;

    img {
      width: 30px;
    }
  }

  // &-list {
  //   &-item {
  //     padding: 0.5em;

  //     a {
  //       display: block;
  //       text-align: center;

  //       img {
  //         width: 30px;
  //       }
  //     }
  //   }
  // }
}
