.p-image {
  position: relative;

  &-caption {
    font-size: rem(12px);
    letter-spacing: 0;
    line-height: 1.2;
    text-align: right;
    color: $color-white;
    text-shadow: 0 0 6px rgba(0, 0, 0, 1), 0 0 6px rgba(0, 0, 0, 1),
      0 0 6px rgba(0, 0, 0, 1), 0 0 6px rgba(0, 0, 0, 1),
      0 0 6px rgba(0, 0, 0, 1), 0 0 6px rgba(0, 0, 0, 1),
      0 0 6px rgba(0, 0, 0, 1), 0 0 6px rgba(0, 0, 0, 1);
    position: absolute;

    &--top {
      top: 5px;
    }

    &--bottom {
      bottom: 5px;
    }

    &--left {
      left: 5px;
    }

    &--right {
      right: 5px;
    }
  }
}
