.p-modal {
  display: none;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &-bg {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-body {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    position: relative;
  }

  &-contents {
    max-height: 500px;
    overflow: scroll;
    padding: 20px;
    background: $color-white;
  }

  &-close {
    width: 40px;
    height: 40px;
    background: $color-white;
    border: 2px solid $color-black;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
    padding: 0;
    text-align: center;

    
    &:active{
      top: -20px;
    }
  }

  &-arrow {
    display: block;
    width: 50px;
    height: 50px;
    font-size: rem(30px);
    line-height: 50px;
    color: $color-white;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 1046;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    @include view-at(sp){
      top: auto;
      bottom:20px;
    }

    &--left {
      left: calc((100% - 600px) / 2);

      @include view-at(sp){
        left: 20px;
      }

      &::before {
        content: "〈";
      }
    }

    &--right {
      right: calc((100% - 600px) / 2);

      @include view-at(sp){
        right: 20px;
      }

      &::before {
        content: "〉";
      }
    }
  }
  &-fade {
    &.mfp-bg {
      opacity: 0;
      transition: all 0.15s ease-out;

      &.mfp-ready {
        opacity: 0.8;
      }

      &.mfp-removing {
        opacity: 0;
      }
    }
    &.mfp-wrap {
      .mfp-content {
        opacity: 0;
        transition: all 0.15s ease-out;
      }

      &.mfp-ready {
        .mfp-content {
          opacity: 1;
        }
      }

      &.mfp-removing {
        .mfp-content {
          opacity: 0;
        }
      }
    }
  }
}
