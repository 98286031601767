/* --------------------------
フッター
-------------------------- */

.p-footer {
  @include view-at(sp) {
    margin-bottom: 90px;
  }
  &-contents {
    padding: 40px 0;
    background: #3f3022;
  }

  &-company {
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: -0.5em;

      @include view-at(sp) {
        justify-content: center;
      }
    }

    &-column {
      padding: 0.5em;
    }

    &-logo {
      font-size: 0;
      line-height: 1;
      text-align: left;

      a {
        display: inline-block;

        img {
          width: 250px;
          height: auto;
        }
      }
    }

    &-instagram {
      display: inline-block;

      img {
        width: 30px;
        height: auto;
      }
    }
  }

  &-copyright {
    font-size: rem(12px);
    line-height: 1.5;
    color: $color-black;
    padding: 8px;
    text-align: center;
  }
}
