/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: rem(16px);
  line-height: 1.5;
  // border-collapse: separate;

  tbody {
    tr {
      
      
      @include view-at(sp) {
        width: 100%;
      }
    }

    th {
      width: 30%;
      color: $color-white;
      padding: 0.75em 16px;
      background: $color-orange;
      vertical-align: middle;
      text-align: center;
      border-bottom: 5px solid $color-white;
      

      @include view-at(sp) {
        padding: 8px 16px;
        display: block;
        width: 100%;
        text-align: left;
      }
    }


    td {
      width: 70%;
      padding: 0 16px;
      border-bottom: 5px solid $color-white;

      @include view-at(sp) {
        padding: 8px 16px;
        display: block;
        width: 100%;
      }
    }
  }
}
