.p-news {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;

  @include view-at(sp) {
    max-width: 100%;
    padding: 0 $contents-padding-sp;
  }

  &-title {
    font-size: rem(24px);
    line-height: 1.5;
    margin-bottom: 16px;
    text-align: center;
  }

  &-list {
    &-item {
      display: flex;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-gray;

      @include view-at(sp) {
        flex-wrap: wrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-date {
    flex-shrink: 0;
    margin-right: 1em;
    margin-bottom: 0;

    @include view-at(sp) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}
