/* --------------------------
スクロールダウン
-------------------------- */
.c-scrolldown {
  width: 48px;
  height: 48px;
  color: $color-white;
  position: absolute;
  bottom:24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  

  @include view-at(sp){
    width: 40px;
    height: 40px;
    bottom: 8px;

    p{
      font-size: rem(12px);
    }
  }

  &::before{
    font-family: 'Material Symbols Outlined';
    content: '\ead0';
  }
}
