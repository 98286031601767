/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: $color-black;
  color: $color-white;
  font-size: rem(14px);
  letter-spacing: 1px;
}
