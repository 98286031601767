/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  background-color: $color-light-gray;

  &-list {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;

    li {
      &::after {
        content: ">";
        padding: 0 1em;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
