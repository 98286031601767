.p-asidebar {
  position: fixed;
  top: 133px;
  left: 0;
  z-index: 100;

  &-list {
    &-item {
      a {
        display: block;
        writing-mode: vertical-rl;
        font-size: rem(16px);
        line-height: 1;
        letter-spacing: 0.1em;
        color: $color-white;
        padding: 2em 1em;
        background: $color-black;
      }
    }
  }
}
