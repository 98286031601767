.p-popup {
  // display: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  &-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 750px;
    // background: #fff;

    @include view-at(sp) {
      width: 85%;
      margin: 0 auto;
    }
  }

  &-close {
    position: absolute;
    top: -25px;
    right: -25px;

    a {
      display: block;
      color: $color-white;
      font-size: rem(30px);
      line-height: 1;
    }
  }
}
