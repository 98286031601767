/* --------------------------
タブ
-------------------------- */

.c-tab {
  &-nav {
    display: flex;

    @include view-at(sp) {
      flex-wrap: wrap;
      margin: -4px;
    }

    &-item {
      flex-grow: 1;

      @include view-at(sp) {
        width: 50%;
        padding: 4px;
      }

      &:last-child {
        a {
          border-right: none;
        }
      }

      a {
        display: block;
        color: $color-black;
        text-align: center;
        padding: 20px;
        background-color: $color-light-gray;
        border-right: 1px solid $color-white;

        @include view-at(sp) {
          padding: 15px 5px;
          font-size: 13px;
        }

        &[aria-selected="true"] {
          background-color: $color-mid-gray;
          color: $color-white;
        }
      }
    }
  }

  &-contents {
    padding: 20px;
    background-color: $color-mid-gray;

    @include view-at(sp) {
      margin-top: 8px;
    }

    &-item {
      display: none;

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}
