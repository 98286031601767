.p-lifeinfo {
  &-map {
    height: 600px;
    background: #000;
  }

  // スクロールさせたい場合は高さを指定
  &-wrapper{
    // p-lifeinfo-mapと同じheightに
    // height: 600px;
    overflow-y: scroll;
  }

  &-list {
    padding: 20px;
    counter-reset: number 0;

    &.menu0{
      >li::before {
        background: red;
      }
    }

    &.menu1{
      >li::before  {
        background: orange;
      }
    }

    &.menu2{
      >li::before  {
        background: yellow;
      }
    }


    &.menu3{
      >li::before {
        background: green;
      }
    }

    &.menu4{
      >li::before {
        background: blue;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      counter-increment: number 1;

      &::before {
        content: counter(number) " ";
        display: inline-block;
        width: 24px;
        height: 24px;
        background: $color-black;
        color: $color-white;
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        margin-right: 0.5em;
      }

      &:last-child {
        margin-bottom: 0;
      }

      

      a{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      
    }
  }

  &-name {
    
    font-size: rem(16px);
  }

  &-distance {
    
    font-size: rem(16px);
  }
}