.mizuta {
  &-keyvisual {
    position: relative;

    &-copy {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include view-at(tab) {
        width: 80%;
        right: 0;
        margin: 0 auto;
      }

      &-wrapper {
        padding: 40px;
        background: rgba(0, 0, 0, 0.4);

        @include view-at(sp) {
          padding: 16px 8px;
        }
      }
    }
  }

  &-main {
    &-copy {
      font-size: rem(48px);
      line-height: 1.25;
      letter-spacing: 0.1em;
      font-weight: $bold;
      color: $color-white;
      text-align: center;
      margin-bottom: 24px;

      @include view-at(tab) {
        font-size: rem(20px);
        line-height: 1.5;
        letter-spacing: 0.05em;
        margin-bottom: 8px;
      }
    }

    &-text {
      line-height: 1.85;
      letter-spacing: 0.1em;
      font-weight: $bold;
      color: $color-white;
      text-align: center;

      @include view-at(tab) {
        font-size: rem(12px);
      }
    }
  }

  &-kodawari {
    &-row {
      display: flex;
      justify-content: space-between;
      margin: -20px;

      @include view-at(tab) {
        flex-wrap: wrap;
        margin: -10px;
      }
    }

    &-column {
      padding: 20px;
      width: 100%;

      @include view-at(sp) {
        padding: 10px;
      }

      &-slider {
        width: 640px;
        flex-shrink: 0;
        margin-bottom: 0 !important;

        @include view-at(tab) {
          width: 100%;
        }
      }
    }

    &-dots {
      margin-top: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;

      > li {
        padding: 0.25em;

        &.slick-active {
          button {
            background: #3e3e3e;
          }
        }

        button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          color: transparent;
          padding: 0;
          border: none;
          background: #ccc;
        }
      }
    }

    &-button {
      margin-right: 120px;

      @include view-at(sp) {
        margin-right: 0;
      }
    }
  }

  &-greeting {
    &-row {
      display: flex;
      justify-content: space-between;
      margin: -20px;

      @include view-at(sp) {
        flex-wrap: wrap;
        margin: -15px;
      }
    }

    &-column {
      padding: 20px;
      width: 100%;

      @include view-at(sp) {
        padding: 15px;
      }

      &-image {
        width: 490px;
        flex-shrink: 0;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }

    &-panel {
      padding: 32px 24px;
      background: $color-white;
      border-radius: 10px;
    }

    &-define {
      display: flex;
      flex-wrap: wrap;

      dt {
        width: 30%;
        font-size: rem(18px);
        line-height: 1.5;
        font-weight: $bold;
        color: $color-orange;

        @include view-at(sp) {
          width: 100%;
          border-bottom: 1px solid $color-gray;
          padding-bottom: 8px;
        }
      }

      dd {
        width: 70%;
        font-size: rem(16px);
        line-height: 1.5;
        letter-spacing: 0.1em;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }
  }

  &-menu {
    background: linear-gradient(
      90deg,
      #534539 0%,
      #534539 50%,
      rgba($color-light-gray, 0.5) 50%,
      rgba($color-light-gray, 0.5) 100%
    );

    &-list {
      &-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-contents {
      padding: 25px;
      border-radius: 4px;
      background: $color-white;
    }
  }

  &-price {
    &-list {
      &-item {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-section {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background: $color-white;
        margin-bottom: 8px;

        @include view-at(sp) {
          flex-wrap: wrap;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-headline {
        font-size: rem(18px);
        line-height: 1.5;
        font-weight: $bold;

        @include view-at(sp) {
          width: 100%;
        }
      }

      &-price {
        font-size: rem(18px);
        line-height: 1.5;
        text-align: right;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }
  }

  &-access {
    margin-top: 50px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-shop {
      width: 460px;
    }

    iframe {
      width: 100%;
      height: 500px;

      @include view-at(sp) {
        height: 240px;
      }
    }
  }

  &-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(18px);
    font-weight: $bold;
    line-height: 1.5;

    padding: 8px 16px;
    background: $color-white;
    border-radius: 10px;

    div {
      width: 60px;

      img {
        display: block;
        width: 60px;
      }
    }
  }
}
