/* --------------------------
フッター
-------------------------- */

.p-fnav {
  &-list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: -0.5em -1em;

    @include view-at(sp) {
      justify-content: flex-start;
      margin: 0;
    }

    &-item {
      padding: 0.5em 1em;

      @include view-at(sp) {
        width: 50%;
        margin-right: 0;
        padding: 0.5em;
      }

      &:last-child {
        margin-right: 0;
      }

      &.current {
        a {
          color: $color-orange;
        }
      }

      a {
        display: inline-block;
        font-size: rem(12px);
        line-height: 1;
        font-weight: $bold;
        letter-spacing: 0.05em;
        color: $color-white;

        @include view-at(sp) {
          line-height: 1.5;
        }

        &::before {
          content: "＞";
          padding-right: 0.25em;
        }
      }
    }
  }
}
