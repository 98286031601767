/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-size: rem(28px);
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  text-align: center;

  @include view-at(sp) {
    font-size: rem(24px);
  }
}

.c-h2 {
  font-size: rem(28px);
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  padding-left: 10px;
  border-left: 8px solid $color-orange;
  margin-bottom: 30px;

  @include view-at(sp) {
    font-size: rem(24px);
  }
}

.c-h3 {
  font-size: rem(20px);
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: $color-orange;
  margin-bottom: 16px;
}

.c-h4 {
  font-size: rem(18px);
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: $color-white;
  text-align: center;
  margin-bottom: 16px;
}

.c-h5 {
  font-size: rem(16px);
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 8px;
}
