.p-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    // vimeoの場合のみ下記が必要

    & ~ p {
      display: none;
    }
  }
}
