/* --------------------------
リンク
-------------------------- */

.c-link {
  text-decoration: underline;

  &--block {
    display: block;

    img {
      display: block;
    }
  }
}
