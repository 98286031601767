/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-orange;
}

.u-text-important {
  color: $color-black;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-serif {
  font-family: "Times New Roman", Times, serif;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $regular;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-gray 70%);
}

.u-text-x-small {
  font-size: rem(12px);
  
}

.u-text-small {
  font-size: rem(14px);
  
}

.u-text-default {
  font-size: rem(16px);
  
}

.u-text-large {
  font-size: rem(18px);
  
}

.u-text-x-large {
  font-size: rem(20px);
  
}
