/* --------------------------
テキスト装飾
-------------------------- */
.u-line-basis {
  line-height: 1;
}

.u-line-tight {
  line-height: 1.25;
}

.u-line-default {
  line-height: 1.5;
}

.u-line-relaxed {
  line-height: 1.75;
}

.u-line-loose {
  line-height: 2;
}
