/* --------------------------
スクロールに合わせてフェードイン
-------------------------- */
.c-fadein {
  opacity: 0;
  transition: all 1s;

  &.js-active {
    opacity: 1;
  }

  &-wrapper {
    overflow: hidden;
  }

  &--top {
    transform: translateY(-20%);

    &.js-active {
      transform: translateY(0);
    }
  }

  &--bottom {
    transform: translateY(100px);

    &.js-active {
      transform: translateY(0);
    }
  }

  &--left {
    transform: translateX(-100px);

    &.js-active {
      transform: translateY(0);
    }
  }

  &--right {
    transform: translateX(100px);

    &.js-active {
      transform: translateY(0);
    }
  }

  // 時間差で表示させる
  @for $i from 0 through 10 {
    // 0〜10以上の場合は「through」の値を変更
    &--#{$i * 1} {
      transition-delay: #{$i * 0.2}s;
      // 変化の待ち時間を「0 * 0.2s」で計算している
    }
  }
}
