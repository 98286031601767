/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: block;
  font-size: rem(20px);
  font-weight: $bold;
  line-height: 1.5;
  padding: 1em 2em;
  color: $color-white;
  background: $color-orange;
  border-radius: 10px;
  text-align: center;

  &--border {
    color: $color-black;
    background: transparent;
    border: 2px solid $color-orange;
  }

  &--small {
    padding: 1em;
  }
}
