.p-mv {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &-title {
    font-size: rem(32px);
    font-weight: $bold;
    color: $color-white;
    line-height: 1;
    letter-spacing: 0.1em;
  }
}
