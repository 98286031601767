/* --------------------------
パネル
-------------------------- */

.c-panel {
  padding: 32px 24px;
  background: $color-white;
  border-radius: 10px;

  &--narrow{
    padding: 24px 16px;
  }

  &--orange{
    background: $color-orange;
  }

  
}
