/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 10;
  background: #2b1a0b;

  @include view-at(sp) {
    height: 50px;
  }

  &-inner {
    height: 100%;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include view-at(tab) {
      padding-right: 30px;
    }

    @include view-at(sp) {
      padding: 10px $contents-padding 10px;
    }
  }

  &-logo {
    font-size: 0;
    line-height: 1;
    text-align: left;

    a {
      display: inline-block;

      img {
        width: 250px;
        height: auto;

        @include view-at(sp) {
          width: 150px;
        }
      }
    }
  }
}
